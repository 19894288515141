import React from "react";
import PropTypes from "prop-types";

class KbygAccomodationsExtraHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { booking } = this.props;
    booking = booking || {}
    if(booking) {
      return (
        <div className="sg-kbyg-accomodations-extra-header">
          <br/>
          <div className="sg-kbyg-body">
            Check-in Date:
          </div>
          <input className="form-control" disabled={true} value={booking['check_in_date']||""}/>
          <br/>


          <div className="sg-kbyg-body">
            Check-Out Date:
          </div>
          <input className="form-control" disabled={true} value={booking['check_out_date']||""}/>
          <br/>


          <div className="sg-kbyg-body">
            Hotel Confirmation Number:
          </div>
          <input className="form-control" disabled={true} value={booking['confirmation']||""}/>
          <br/>

        </div>
      )
    }
    return ""
  }
}

export default KbygAccomodationsExtraHeader;
